
// @ts-nocheck


export const localeCodes =  [
  "en",
  "nl",
  "de",
  "pl",
  "ro",
  "el",
  "it",
  "es"
]

export const localeLoaders = {
  "en": [{ key: "../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.17.19_magicast@0.3.5_nuxt@3.1_4jtwim2rp6qukxe3c4fbbppvju/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json", load: () => import("../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.17.19_magicast@0.3.5_nuxt@3.1_4jtwim2rp6qukxe3c4fbbppvju/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json" /* webpackChunkName: "locale__vercel_path0_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_base_641_1_0__64bugsnag_43core_647_25_0_esbuild_640_17_19_magicast_640_3_5_nuxt_643_1_4jtwim2rp6qukxe3c4fbbppvju_node_modules__64bttr_devs_nuxt_layer_base_locales_en_json" */), cache: true }],
  "nl": [{ key: "../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.17.19_magicast@0.3.5_nuxt@3.1_4jtwim2rp6qukxe3c4fbbppvju/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json", load: () => import("../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.17.19_magicast@0.3.5_nuxt@3.1_4jtwim2rp6qukxe3c4fbbppvju/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json" /* webpackChunkName: "locale__vercel_path0_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_base_641_1_0__64bugsnag_43core_647_25_0_esbuild_640_17_19_magicast_640_3_5_nuxt_643_1_4jtwim2rp6qukxe3c4fbbppvju_node_modules__64bttr_devs_nuxt_layer_base_locales_nl_json" */), cache: true }],
  "de": [],
  "pl": [],
  "ro": [],
  "el": [],
  "it": [],
  "es": []
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "active": true,
      "files": [
        "/vercel/path0/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.17.19_magicast@0.3.5_nuxt@3.1_4jtwim2rp6qukxe3c4fbbppvju/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json"
      ]
    },
    {
      "code": "nl",
      "name": "Dutch",
      "active": true,
      "files": [
        "/vercel/path0/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.17.19_magicast@0.3.5_nuxt@3.1_4jtwim2rp6qukxe3c4fbbppvju/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json"
      ]
    },
    {
      "code": "de",
      "name": "German",
      "active": true
    },
    {
      "code": "pl",
      "name": "Polish",
      "active": true
    },
    {
      "code": "ro",
      "name": "Romanian",
      "active": true
    },
    {
      "code": "el",
      "name": "Greek",
      "active": true
    },
    {
      "code": "it",
      "name": "Italian",
      "active": true
    },
    {
      "code": "es",
      "name": "Spanish",
      "active": true
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "./locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "active": true,
    "files": [
      {
        "path": "/vercel/path0/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.17.19_magicast@0.3.5_nuxt@3.1_4jtwim2rp6qukxe3c4fbbppvju/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json"
      }
    ]
  },
  {
    "code": "nl",
    "name": "Dutch",
    "active": true,
    "files": [
      {
        "path": "/vercel/path0/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.17.19_magicast@0.3.5_nuxt@3.1_4jtwim2rp6qukxe3c4fbbppvju/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json"
      }
    ]
  },
  {
    "code": "de",
    "name": "German",
    "active": true,
    "files": []
  },
  {
    "code": "pl",
    "name": "Polish",
    "active": true,
    "files": []
  },
  {
    "code": "ro",
    "name": "Romanian",
    "active": true,
    "files": []
  },
  {
    "code": "el",
    "name": "Greek",
    "active": true,
    "files": []
  },
  {
    "code": "it",
    "name": "Italian",
    "active": true,
    "files": []
  },
  {
    "code": "es",
    "name": "Spanish",
    "active": true,
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
